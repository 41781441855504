﻿.ui-select-container {
  position: relative;
}

input[type='number'].no-spin::-webkit-outer-spin-button,
input[type='number'].no-spin::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'].no-spin {
  /* Firefox */
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
}

.overflow:hover {
  overflow-y: auto;
}

.pos-rel {
  position: relative;
}

div.uk-nav-divider {
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 1px 0 #fff;
}

.menuBar .uk-nav-divider {
  margin-bottom: 20px;
  opacity: 0.5;
}

.menuBar .uk-nav-dropdown {
  margin-top: 1em;
  border-top: 1px solid #e5e5e5;
  padding-top: 0.5em;
}

.menuBar .uk-nav-dropdown a {
  font-size: 1rem;
  color: #0a3748;
  margin-bottom: 0;
}

.roundBtn.disabled,
a.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: default;
}

/** LOGIN **/

.image-bgr {
  background-image: url(images/ecus_bilar_2.jpg);
  background-position: center;
  background-size: cover;
  padding-bottom: 0px;
  min-height: 700px;
}

.ecus-login-bgr {
  box-sizing: border-box;
  padding-bottom: 100px;
  height: calc(100vh - 148px);
}

.form-signin {
  padding: 30px 38px 66px;
  margin: 0 auto;
  background-color: #eee;
  border: 1px dotted #2b99d4;
  border-radius: 1em;
}

.form-signin input[type='text'] {
  margin-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin-white {
  background-color: #f1f1f1;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2), 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  border: 0px dotted #fff;
  border-radius: 1em;
}

.form-signin-heading {
  text-align: center;
  margin-bottom: 30px;
}

/** END LOGIN **/

.footer-row-ecus {
  background-color: #2b99d4;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 20px;
  border-top: 2px solid #fff;
  min-height: 12%;
}

.footer-btn {
  display: inline-block;
  padding: 10px 20px;
  border: solid 1px #fff;
  border-radius: 8px;
  min-width: 150px;
  margin-bottom: 10px;
  color: #fff;
}

.footer-btn:hover,
.footer.btn:active {
  background-color: #36a6e2;
  color: #fff;
}

/** PRESENCE **/

#presence-container {
  position: absolute;
  top: 0;
  right: 0;
}

#presence-container .uk-dropdown {
  padding-top: 0;
  padding-bottom: 0;
  min-width: 250px;
}

#presence-container .uk-dropdown li {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f9f9f9;
  position: relative;
}

#presence-container .uk-dropdown li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

#presence-container .presence-profile img {
  position: absolute;
  margin: 2px 5px 5px;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
}

#presence-container p {
  margin: 5px 0 0;
  min-height: 50px;
}

#presence-container .presence-name {
  color: #000000;
  font-weight: normal;
  margin-left: 50px;
}

#presence-container .ecus-badge {
  width: 50px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 25%;
}

/** END PRESENCE **/

/** AUTOCOMPLETE **/

.uk-dropdown .no-results > span {
  padding: 5px 15px;
  color: #ccc;
}

/** END AUTOCOMPLETE **/

/** MODAL **/

.uk-modal.uk-open {
  display: block;
  overflow-y: auto;
}

.uk-modal-header {
  overflow: hidden;
  clear: both;
}

.uk-modal.wide-modal .uk-modal-dialog {
  width: 80vw;
}

/** END MODAL **/

/** TREEVIEW **/

.ui-tree {
  width: 100%;
}

.ui-tree-container {
  max-height: 80vh;
}

/** END TREEVIEW **/

/** ERRAND DETAILS **/

.errand-info-div {
  margin-top: 30px;
}

/** END ERRAND DETAILS **/

/** VERSIONS AND DIFFS **/

#case-versions tr.active td {
  font-weight: bold;
}

.ecus-diff-propname {
  width: 17%;
}

.ecus-diff-value {
  width: 40%;
}

.ecus-diff-swap {
  width: 3%;
}

.ecus-diff-table th.swap i {
  position: relative;
  opacity: 1;
  margin-left: 0;
  padding-right: 0;
  top: auto;
}

.ecus-diff-table th.swap:hover i {
  top: auto;
}

.ecus-diff-table .old-value,
.ecus-diff-table .property-name {
  text-align: right;
}

.ecus-diff-table .new-value {
  text-align: left;
}

.ecus-diff-table .swap {
  text-align: center;
  vertical-align: middle;
}

.ecus-diff-table td.old-value {
  text-decoration: none;
  color: #b30000;
  background: #fadad7;
}

.ecus-diff-table td.new-value {
  background: #eaf2c2;
  color: #406619;
  text-decoration: none;
}

/** END VERSIONS AND DIFFS **/

/** ADMIN UI **/

#edit-customer .uk-form label {
  text-align: right;
}

/** END ADMIN UI **/

/** WAREHOUSE UI **/

[warehouse-top-bar] {
  height: 100%;
}

/** END WAREHOUSE UI **/

/** FORMS **/

p-dropdown.uk-form-success .ui-dropdown {
  border-color: #8ec73b !important;
  background: #fafff2 !important;
  color: #659f13 !important;
}

p-dropdown.uk-form-danger .ui-dropdown {
  border-color: #dc8d99 !important;
  background: #fff7f8 !important;
  color: #d85030 !important;
}

p-dropdown.uk-form-danger .ui-dropdown .ui-dropdown-trigger,
p-dropdown.uk-form-danger .ui-dropdown .ui-dropdown-label,
p-dropdown.uk-form-success .ui-dropdown .ui-dropdown-trigger,
p-dropdown.uk-form-success .ui-dropdown .ui-dropdown-label {
  background: inherit;
}

.hidden-form-group {
  display: none !important;
}

[rel='clickable'] {
  pointer-events: auto !important;
}

[rel='clickable']:hover {
  cursor: pointer;
  color: #2b99d4 !important;
}

.table-nums {
  font-variant: tabular-nums;
}

.row-sums {
  text-align: right;
}

.row-sums .table-nums {
  margin-left: 10px;
}

.uk-table .table-nums {
  text-align: right;
}

#arende .uk-flex.form-group,
#tull .uk-flex.form-group,
#frakt .uk-flex.form-group {
  align-items: normal;
}

#arende .uk-flex.form-group label,
#tull .uk-flex.form-group label,
#frakt .uk-flex.form-group label {
  line-height: 30px;
}

.js-upload-finished .uk-badge {
  background-color: #009dd8;
  color: #fff;
}

.js-upload-finished .uk-badge-success {
  background-color: #2b99d4;
}

input.uk-input.ecusColorDark {
  color: #fff;
}

input.uk-input.ecusColorDark:not(:disabled):hover {
  background-color: #2b99d4;
}

.ecom-checkbox-container {
  line-height: 30px;
}

.fixed-table {
  table-layout: fixed;
  overflow: hidden;
}

.summary-declarations-table .ui-dropdown {
  min-width: auto;
}

.summary-declarations-table input {
  height: 30px !important;
  padding: 4px 6px !important;
  border: 1px solid #e8e8e8 !important;
}

.uk-form-row.hidden-form-group + .uk-form-row {
  margin-top: 0;
}

.ecom-form-text {
  line-height: 30px;
  text-align: center;
  font-weight: bold;
}

.with-field-code {
  position: relative;
}

.field-code {
  border: 0.5px solid #e8e8e8;
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 4px 2px 2px;
  z-index: 1000;
  background: white;
  pointer-events: none;
  width: 16px;
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.field-code ~ input {
  padding-right: 25px !important;
}

.field-code ~ p-dropdown .ui-dropdown .ui-dropdown-trigger {
  margin-right: 21px;
}

.field-code ~ p-dropdown .ui-dropdown .ui-dropdown-label {
  padding-right: 4em;
}

.varuTable > div .with-description > input {
  width: calc(100% - 16px);
}

/** END FORMS **/

.cover-spin {
  position: relative;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cover-spin::before {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
  display: block;
  content: '';
}

.cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border-style: solid;
  border-color: grey;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  z-index: 10001;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.cover-spin.cover-spin-top::after {
  top: 45px;
}

.search-result-hit td {
  background-color: #bee5eb;
}

body .condensed-buttons .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.3em 0.6em;
}

base-modal-layout .uk-modal {
  z-index: 1000;
}

.uk-text-italic {
  font-style: italic;
}

.version-box {
  position: absolute;
  bottom: 5px;
  color: #aaa;
  font-size: 60%;
  margin-left: 10px;
}

.uk-label {
  color: #fff;
  vertical-align: text-top;
  font-size: 0.6em;
  padding: 1px 4px 0;
}

.uk-label-dark {
  background: #0a3748;
  color: #fff;
}

body .ui-dropdown-panel .ui-dropdown-items {
  max-width: 400px;
}

.monospace {
  font-family: 'Courier New', Courier, monospace;
}

.has-loading {
  position: relative;
}

.has-loading::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -100;
  display: block;
  color: #aaa;
  text-align: center;
  opacity: 0;
  -webkit-transition: opacity ease-out 250ms;
  transition: opacity ease-out 250ms;
  -webkit-font-smoothing: antialiased;
}

.has-loading::after {
  position: absolute;
  content: '';
  top: 100px;
  left: 50%;
  margin: -16px 0 0 -16px;
  z-index: -100;
  display: block;
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
  opacity: 0;
  -webkit-transition: opacity ease-out 250ms;
  -o-transition: opacity ease-out 250ms;
  transition: opacity ease-out 250ms;
}

.has-loading.loading::after,
.has-loading.loading::before {
  z-index: 100;
  opacity: 1;
}

.ecom-edit-case-rows-quickentry label.uk-form-label {
  margin-top: 0;
}

.ecom-edit-customer-actor-automatic-measures
  uikit-form-element
  > .uk-form-row {
  min-height: auto;
}

.bigger-and-bolder-text {
  font-size: 18px;
  font-weight: bold;
  text-transform: none;
}

.sticky-overview-column {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  white-space: nowrap;
  background: white;
  right: 0;
}

.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover
  td.sticky-overview-column {
  background: #e9ecef;
}

.hide-p-tabview-nav .p-tabview .p-tabview-nav{
  display: none;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.p-dropdown-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  max-width: 700px;
}

.p-autocomplete-panel {
  position: absolute;
  max-width: 700px;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  position: relative;
  overflow: hidden;
}

.p-autocomplete-item {
  white-space: normal !important;
}

.p-dropdown-item.highlighted-option:not(.p-highlight):not(.p-disabled)
 {
  color: #495057 !important;
  background: #e9ecef !important;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

.ukfe .p-dropdown-clear-icon {
  height: 10px;
  width: 10px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
  height: 10px;
  width: 10px;
}

.p-icon-wrapper {
  height: 10px;
  width: 10px;
}
.p-column-filter-menu-button .p-icon-wrapper .pi-filter-icon{
  height: 10px;
  width: 10px;
}
.p-column-filter-menu-button
{  height: 25px;
  width: 25px;
  align-items: self-start;
}

.upload-file-overview {
  display: flex;
  align-items: center;
}

.upload-file-comments {
  position: relative;
  margin-left: 2%;
}

.upload-file-comments-close {
  position: absolute;
  bottom: 35%;
  left: 95%;
}

.p-multiselect-items-wrapper .p-multiselect-items{
  padding-left: 0px;
}

p-tieredmenusub ul{
  padding-left: 0px;
}
