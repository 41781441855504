  /* BASE 2021 */

html,
body {
  color: #0d2e40;
  background-color: #f9f9f9;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
.strong {
  font-weight: 600;
  text-transform: none;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
.strong,
.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle,
input,
.p-component,
.p-inputtext,
.p-link {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

input,
label,
.uk-input,
.p-component,
.p-inputtext {
  font-size: 13px;
}

.remove-margin-top {
  margin-top: -20px;
}
.margin-xsmall-top {
  margin-top: 5px;
}
.small-padding-left {
  padding-left: 10px;
}
.statusBar {
  background-color: #0a3748;
  padding: 7px 0px;
  margin: 2px -30px 0;
}

.statusBar ul {
  display: flex;
  margin: 0;
}

.statusBar li {
  list-style: none;
  padding-right: 20px;
  font-size: 1em;
  color: #fff;
  font-weight: 300;
}
.colPadding-x {
  padding-left: 30px;
  padding-right: 30px;
}
.uk-link,
a {
  color: #195c7f;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

.uk-link:hover {
  text-decoration: none;
  color: #2a99d4;
}

.uk-button {
  background-color: #195c7f;
  text-transform: none;
  color: #fff;
  padding: 0 20px;
  transition: 0.3s;
}

.uk-button:focus,
.uk-button:hover:not(:disabled),
.uk-navbar.uk-toolbar .uk-button:hover {
  background-color: #2a99d4;
  color: #fff;
  outline: 0;
  text-decoration: none;
}

/* .uk-button.uk-active,
.uk-button:active {
  background-color: #cdd4d8;
  color: #0d2e40;
} */

.uk-button:disabled {
  background-color: #cdd4d8;
}

.uk-button-primary {
  background-color: #7fc2e5;
}

.uk-button-primary:focus:not(:disabled),
.uk-button-primary:hover:not(:disabled) {
  background-color: #35b3ee;
  color: #fff;
}

.uk-button.uk-button-link:focus,
.uk-button.uk-button-link:hover {
  background-color: transparent;
  color: #2a99d4;
}

.uk-button-primary.uk-active,
.uk-button-primary:active {
  background-color: #0091ca;
  color: #2b99d4;
}

.uk-button-secondary {
  background-color: #79909b;
}

.ui-inputgroup .uk-button {
  line-height: normal;
}

.uk-button-link {
  color: #0a3748;
  background: transparent;
}

.uk-button-link:hover {
  color: #35b3ee;
  background: transparent;
}

.uk-icon-button {
  background-color: #195c7f;
  color: #fff;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
}

.combo-btn-hover:hover .uk-icon-button,
.uk-icon-button:hover {
  background-color: #2a99d4;
  color: #fff;
  text-decoration: none;
}

.uk-table td,
.uk-table th {
  padding: 12px 8px;
}

.uk-table.cropper td div,
.uk-table.cropper th div {
  overflow: hidden;
  white-space: nowrap;
  min-width: 10%;
  text-overflow: ellipsis;
  font-size: 0.95em;
}

.uk-table.cropper td:hover div,
.uk-table.cropper th:hover div {
  overflow: visible;
  white-space: nowrap;
  padding-right: 5px;
  top: 12px;
  z-index: 1;
  position: absolute;
  color: #222;
}

.uk-table-hover tbody tr:hover {
  background: #f9f9f9;
}

.table-padding {
  padding: 20px 20px 20px 25px;
}

.uk-table {
  font-size: 13px;
  line-height: 16px;
}

.uk-table th,
.p-datatable .p-datatable-thead > tr > th {
  color: #0a3748;
}
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  vertical-align: middle;
}
.p-datatable .p-datatable-tbody > tr > td{
  overflow-wrap: break-word;
}
.p-datatable .p-datatable-tbody > tr.last-td-right > td:last-of-type {
  text-align: right;
}
.p-datatable .p-datatable-thead > tr > th {
  background: #fff;
  padding: 0.1rem 1rem;
}
/* .ecusUser {
  position: absolute;
  bottom: 110px;
  width: 60px;
  left: 0;
} */

.shrink,
.side-bar,
.side-bar-item-holder a,
.main-content,
.ecusName {
  transition: all 0.5s ease;
}

.main-content {
  padding-left: 250px;
}

.colPadding {
  padding: 70px 30px 30px;
}

/* MENY */
.side-bar {
  background-color: #0d2e40;
  flex-basis: 250px;
  width: 250px;
  height: 100vh;
  position: fixed;
  z-index: 300;
}

/* menyitems */
.side-bar-item-holder {
  font-size: 16px;
  font-weight: 600;
}

.side-bar-item-holder a {
  color: #cdd4d8;
  padding-bottom: 20px;
}

.side-bar-item-holder a.activeMenu {
  color: #edbb5f;
}

.side-bar-item-holder a:hover {
  color: #fcdea8;
  text-decoration: none;
}

.side-bar-item-icon {
  font-size: 30px;
  text-align: center;
}

.logo {
  margin-bottom: 0 !important;
  position: absolute;
  bottom: 25px;
  left: 0;
  font-size: 20px !important;
  font-weight: 600;
  color: #cdd4d8;
  text-align: center;
  width: 100%;
}

.logo img {
  width: 70%;
  height: auto;
}

/* toggle */
.toggleUserMenu {
  margin-top: 7px;
  margin-bottom: 30px;
  text-align: right;
}

.toggleUserMenu a {
  background-color: #fcdea8;
  border-radius: 50%;
  padding: 4px 12px;
  margin-right: -13px;
  color: #0d2e40;
  box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
  border: 0.5px solid #0d2e40;
}

.shrink + .main-content {
  padding-left: 80px;
}

.shrink {
  width: 80px;
  padding: 0;
}

.shrink .d-none-shrink {
  display: none;
}

.d-none-shrink {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.shrink .side-bar-item-holder .uk-width-1-3 {
  width: 100%;
}

/* end MENY */

/* Sticky top bar */
.top-nav-design {
  background-color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
}

.topbar-nav {
  z-index: 200;
  padding-left: 30px;
}

.topbar-login {
  width: 100%;
  z-index: 150;
  box-shadow: 0 0px 15px rgb(0 0 0 / 8%);
}

.topbar-login .account-btn {
  right: 0px;
}

.uk-navbar-nav > li > a:not(.uk-button) {
  height: 40px;
  min-height: 40px;
  text-transform: none;
  color: #0d2e40;
}

.uk-navbar-nav > li > a.uk-button {
  color: #fff;
}

.uk-navbar-nav > li > span {
  font-size: 0.875rem;
  display: inline-block;
  padding-top: 10px;
  font-weight: bold;
}

body .ui-button:enabled:focus {
  box-shadow: none;
}

.casemanager-dropdown ul li {
  display: flex;
  border-bottom: 1px solid #cdd4d8;
  align-items: center;
}

.casemanager-dropdown ul li:last-of-type {
  border-bottom: none;
}

.statusIcon {
  font-size: 1.2em;
  color: #0d2e40;
}

.uk-dropdown {
  max-height: 700px;
  overflow: auto;
  padding: 0;
}

.uk-dropdown li a {
  padding: 8px 15px;
  display: block;
}

.uk-navbar-nav > li:hover > a:not(.uk-button),
.uk-navbar-nav > li > a.uk-open:not(.uk-button),
.uk-navbar-nav > li > a:focus:not(.uk-button) {
  color: #2a99d4;
}

a.link-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

a.link-disabled:hover,
.uk-navbar-nav > li:hover > a.link-disabled {
  color: inherit;
}

/* end Sticky top bar */

/* Tabs */
.tab {
  margin: 0;
  padding: 0;
}

.tab::after,
.tabContent {
  content: '';
  clear: both;
}

ul.tab {
  display: flex;
}

.tab li {
  list-style: none;
}

.tab li a {
  display: block;
  padding: 5px 10px 20px 10px;
  color: #ccc;
  font-weight: 600;
  margin-bottom: -2px;
}

.tab li.uk-active a {
  display: block;
  border-bottom: 2px solid #000;
  color: #0a3748;
}

.mainBar .tab {
  /* .tab-large-text */
  font-size: 1.2em;
  padding: 0px 0px 0px 0px;
}

.uk-tab.tab > * > a {
  text-transform: none;
  font-size: 1em;
}

.uk-tab > * {
  padding-left: 0;
}

.uk-tab::before {
  border-bottom: none;
}

/* end Tabs */

/* pagination */

.uk-pagination > .uk-active > *,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #0d2e40;
  border: 1px solid #0d2e40;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: transparent !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 0;
}
.uk-pagination > * {
  padding-left: 5px;
}

.uk-pagination > * > * {
  padding: 0px 7px;
  border: 1px solid transparent;
}

/* end pagination */

/* table */
.xlCol {
  width: 230px;
}
.lgCol {
  width: 150px;
}

.medCol {
  width: 130px;
}

.smallMedCol {
  width: 110px;
}

.smallCol {
  width: 85px;
}

.timestampCol {
  max-width: 115px;
  width: 115px;
}

.miniCol {
  width: 50px;
}

.microCol {
  width: 20px;
}

.uk-table.cropper {
  table-layout: fixed;
}

.uk-table td,
.uk-table th {
  border-bottom: 1px solid #f1f1f1;
}

.uk-table tr:last-of-type td {
  border-bottom: 0px solid #f1f1f1;
}

.uk-table.cropper td {
  vertical-align: middle;
}

.uk-table.cropper.top td {
  vertical-align: top;
}

.uk-table.cropper td,
.uk-table.cropper th {
  position: relative;
}

.uk-table.cropper td div,
.uk-table.cropper th div {
  overflow: hidden;
  white-space: nowrap;
  min-width: 10%;
  text-overflow: ellipsis;
  font-size: 0.95em;
}

.uk-table.cropper td:hover div,
.uk-table.cropper th:hover div {
  overflow: visible;
  white-space: nowrap;
  background-color: #f9f9f9;
  padding-right: 5px;
  top: 12px;
  z-index: 1;
  position: absolute;
  color: #222;
}

.uk-table.cropper .overDue td:hover div {
  background-color: #f1f1f1;
}

.uk-table.cropper th:hover div {
  background-color: #fff;
}

.uk-table.cropper td:hover div.cropperIgnore,
.uk-table.cropper th:hover div.cropperIgnore {
  position: relative;
  padding-right: 0px;
  top: 0;
}

th a {
  color: #333;
}

th a:hover {
  color: #000;
}

.uk-table.sticky-toolbar thead th {
  position: sticky;
  top: 40px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.uk-table th {
  text-transform: unset;
  font-weight: bold;
}

.uk-table.cropper td div.uk-dropdown {
  min-width: 200px;
  background-color: #fff;
}

tr.cancelled td {
  padding-top: 4px;
  padding-bottom: 4px;
  color: gray;
  opacity: 0.5;
}

tr.cancelled:hover td {
  opacity: 1;
}

.uk-table.cropper tr.cancelled td:hover div:not(.uk-dropdown) {
  position: relative;
  top: auto;
}

tr.cancelled .tableMenu {
  padding-top: 4px;
  padding-bottom: 4px;
}

.case-row-table table tr td .uk-form-stacked {
  margin-left: 6px;
  min-width: 150px;
}

.case-row-table table tr td .required-star {
  display: none;
}
.p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 0.1rem #a6d5fa;
}

.p-column-filter-overlay-menu .p-multiselect-label{
  max-width: 250px;
}

/* end table */

/* misc */

.uk-navbar .ui-dropdown,
.p-dropdown {
  height: 30px;
}

.uk-navbar .uk-animation-slide-left {
  font-size: 13px;
}

.uk-card-default {
  color: #0d2e40;
}

body .span-flex .ui-inputgroup .ui-inputgroup-addon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

body .custom-badges .ui-selectbutton .ui-button,
.p-selectbutton .p-button.p-highlight {
  background: none;
  border-color: #0091ca;
  margin-right: 5px;
  font-size: 0.8rem;
  transition: background-color 0.2s;
}
body .ui-selectbutton .ui-button,
.p-selectbutton .p-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333;
  transition: background-color 0.2s, box-shadow 0.2s;
}
.p-button {
  padding: 0.4rem;
}
.p-dropdown {
  border-radius: 0;
  border: 1px solid #e5e5e5;
}
.align-self-center {
  align-self: center;
}
.align-self-stretch {
  align-self: stretch;
}
body .custom-badges .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.25rem 1em;
}

body
  .custom-badges
  .ui-selectbutton
  .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background: #2a99d4;
  color: #fff;
}

body
  .custom-badges
  .ui-selectbutton
  .ui-button.ui-state-active:not(.ui-state-disabled) {
  background: #7fc2e5;
  border-color: #7fc2e5;
}

body
  .custom-badges
  .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background: transparent;
  border-color: #7fc2e5;
}

body .custom-badges .ui-inputgroup .ui-inputgroup-addon .fa {
  line-height: 2.1;
}
body .statusBar .ui-dropdown .ui-dropdown-clear-icon,
body .statusBar .ui-dropdown .ui-dropdown-trigger {
  color: #fff;
}
.text-blue {
  color: #7fc2e5;
}
/* end misc */

/* import from old uikit */

.activeBtn {
  background-color: #0a3748 !important;
  color: #fff !important;
}

.smallText {
  font-size: 0.8em;
  font-weight: 600;
}

#meddelande textarea {
  width: 100%;
}

/* meddelanden i skapa/redigera ärende */
.btnRightTab {
  float: right;
}

.messageBox {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 5px !important;
}

.meddelandeStatus {
  font-size: 0.8em;
}

.meddelandeStatus.with-form {
  order: 2;
}

.meddelandeStatus .uk-form {
  margin-top: 5px;
}

.message-file a {
  margin-left: 5px;
}

.hiddenIntern .messageBox {
  transition: color 0.1s ease;
  transition: border-color 0.1s ease;
}

.hiddenIntern .messageBox:hover {
  color: #777;
  border-color: #bebebe;
}

.client .messageBox {
  margin-left: 0px;
  border-radius: 10px;
  background-color: #0a3748;
  color: #fff;
}

.hiddenIntern .messageBox {
  background-color: #fff;
  color: #ccc;
  border: 2px dashed #f1f1f1;
}

.btnRightTab a > span {
  top: -10px;
  right: -10px;
  background-color: #fff;
  border: 1px solid #195c7f;
  border-radius: 50px;
  padding: 0 6px;
  line-height: normal;
  font-size: 80%;
}
/* end meddelanden i skapa/redigera ärende */

.floatRight {
  float: right;
}

.floatLeft {
  float: right;
}

.floatRightMargin {
  margin-right: 0;
  margin-left: auto;
}

.overflow:hover {
  overflow-y: scroll;
}

/* tullager table */
.overDue {
  background-color: #f9f9f9;
}

.overDue:hover td {
  background-color: #f4f4f4;
}

.tullager td,
.tullager th,
.tullagerLeft td,
.tullagerLeft th {
  position: relative;
}

.tullagerLeft .uk-button-dropdown {
  padding: 0px 8px;
  cursor: pointer;
}

.tullagerBtn {
  margin-right: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* end tullager table */

.userBar {
  background-color: #f9f9f9;
  border-right: 1px solid #f1f1f1;
  min-width: 350px;
  flex-basis: 350px;
  width: 350px;
  /* Make room for version box */
  height: calc(100% - 45px);
}

.firstLineBar h4,
.firstLineBar h5 {
  cursor: pointer;
}

.ListNav > * {
  color: #0a3748;
  text-transform: none;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}

.activeListNav a {
  color: #2b99d4 !important;
  text-decoration: none;
}

.firstLineBar {
  background-color: #fff;
  border-right: 1px solid #f1f1f1;
  min-width: 350px;
  flex-basis: 350px;
  width: 350px;
}

.mainBar {
  width: 100%;
}

.tableMenu {
  display: block;
  position: absolute;
  right: 0;
  padding: 12px;
  top: 3px;
  /* edit 2020 */
  margin-right: -22px;
  cursor: pointer;
}

a.plus {
  position: absolute;
  right: 0;
  margin-right: -15px;
  top: 0;
  margin-top: 8px;
}

.firstLineBar .uk-dropdown,
.firstLineBar .uk-dropdown-blank {
  width: 174px;
}

.boxCreate {
  background-color: #fff;
  box-shadow: 0px 0px 30px #ebebeb;
  padding: 20px 20px;
}

.boxTitle {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0px 20px 10px;
  border-bottom: 1px #f1f1f1 solid;
}

.uk-form .uk-button {
  font-size: 13px;
}

.addBtnKund {
  margin-top: -30px;
  padding: 20px 0px 20px 0px;
}

.roundBtn {
  font-weight: 3em;
  padding: 17px 20px;
  border-radius: 50%;
  background-color: #f1f1f1;
}

.roundBtnSmall {
  padding: 7px 10px;
  background-color: #0a3748;
  color: #fff;
}

.addListItem {
  margin-right: -12px !important;
}

.listGroupTable i {
  opacity: 1;
}

.listGroupTable input:focus,
.listGroupTable select:focus {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 95%,
    #999 95%
  ) !important;
}

.listGroupTable a {
  color: #0a3748;
}

.btnControls {
  margin: 20px 0px 50px 0px;
}

.addTo {
  float: right;
  margin-top: -12px;
  font-size: 0.9em;
}

.addTo a:hover .roundBtnSmall {
  background-color: #3399ff;
}

.transportRadio input,
.sortAfterText input {
  display: none !important;
}

.transportRadio label {
  color: rgb(177, 177, 177);
  cursor: pointer;
  font-size: 1.3em;
}

.transportRadio input:checked + label {
  color: #0a3748;
}

.transportRadio label {
  margin-right: 10px;
}

.left-side-form h4:first-of-type {
  margin-top: 0px;
}

.left-side-form h4 {
  margin-top: 50px;
  border-bottom: 1px solid #f1f1f1;
}

.left-side-form h5 {
  margin-top: 25px;
  border-bottom: 1px solid #f1f1f1;
}

.left-side-form #importFieldsContainer,
.left-side-form #exportFieldsContainer {
  margin-bottom: 20px;
  padding-bottom: 15px;
}

select:invalid {
  color: #ccc !important;
}

.uk-form-icon:not(.uk-form-icon-flip) > select {
  padding-left: 26px !important;
}

#userBar .uk-text-center {
  margin: 40px 0px 10px 0px;
}

.headerUserTable {
  font-size: 0.8em;
}

.userTable li:first-of-type {
  padding-top: 20px;
}

.userTable li {
  display: flex;
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.userExtraInfo {
  border-top: 1px solid #ccc;
  padding: 5px;
  margin-top: 5px;
  font-size: 0.9em;
}

.userTable a {
  text-decoration: none;
  color: #666;
}

.userContent {
  flex-basis: 100%;
}

.downIcon {
  margin-left: auto;
  cursor: pointer;
  text-align: right;
  padding-left: 20px;
  padding-right: 10px;
}

.uk-icon-angle-down {
  cursor: pointer;
}

.filterHolder > div {
  margin-right: 10px;
}

.filterFritext {
  border-left: 1px solid #f1f1f1;
  padding-left: 20px;
  margin-left: auto;
  margin-top: -20px;
  margin-bottom: -10px;
  padding-top: 20px;
}

.filterFritext input {
  border: 0px !important;
}

.filterHolder {
  padding: 20px 20px 10px 20px;
  font-size: 1em;
  border: 1px solid #f1f1f1;
  vertical-align: middle;
  display: flex;
  margin-bottom: 5px;
}

#arende > .form-group > * {
  margin-right: 3px;
}

.tullagerTop #arende > .form-group > * {
  padding-right: 10px;
}

.listGroupTable input,
.listGroupTable select,
.listGroupTable object,
.tullager input,
.tullager select,
.tullager object {
  padding: 0px !important;
  border: 0px !important;
  margin: 0 !important;
  height: auto !important;
  background-color: transparent !important;
  color: #777 !important;
  box-sizing: border-box;
  line-height: 1;
}

.tullager input,
.tullager select,
.tullager object {
  font-size: 1em;
  line-height: 16px;
}

.tullagerTop.filterHolderSmall {
  padding: 40px 30px 40px 30px;
  background-color: #fafafa;
  margin-top: 0px;
}

.tullager {
  border: 0;
}

.tullheadline {
  border-bottom: 2px solid #0a3748;
}

.tullager.firstTable {
  padding-top: 20px;
  padding-bottom: 10px;
}

.uk-icon-remove {
  color: #9d6c6f;
}

#spacer {
  font-size: 0.1em;
  line-height: 0;
  min-height: 0;
}

.left-flex {
  margin-left: auto;
}

.center {
  text-align: center;
}

.tullagerDay {
  margin-top: -10px;
  margin-bottom: 20px;
}

.activeList {
  background-color: #f2f2f2;
}

.firstLineBar .col-sm-6 .uk-hidden h4 {
  opacity: 0.5;
}

.filterHolderSmall {
  padding: 20px 0px 10px 0px;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 5px;
}

.sortAfterText label {
  font-size: 1em;
  padding: 5px 10px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  cursor: pointer;
}

.sortAfterText label:last-of-type {
  border-right: 1px solid #f1f1f1;
}

.sortAfterText input:checked + label {
  background-color: #0a3748;
  color: #fff;
}

.filterHolderSmall input {
  margin-right: 5px;
}

.tableSetting {
  padding: 7px;
}

.filterSub {
  margin-bottom: 50px;
}

.filterSub > div {
  margin-left: 2px;
}

.filterHolder div div {
  border: 1px solid #f1f1f1;
  padding: 0px 10px;
}

.filterHolder strong {
  font-size: 0.8em;
}

.filterHolder label {
  font-size: 1em;
  vertical-align: middle;
}

.tab a {
  text-decoration: none;
}

.userTable li:last-of-type {
  border-bottom: 0px solid #ccc;
}

.userBar ul {
  margin: 0px;
  padding: 0px;
}

.userBar li {
  list-style: none;
}

.breadcrumb {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 0px;
  margin-bottom: 50px;
}

.breadcrumb a {
  color: #999;
}

@media (max-width: 1220px) {
  .userBar {
    position: absolute;
    min-width: 0px;
    flex-basis: 0px;
    width: 0px;
    margin-left: 60px;
    left: 0;
    z-index: 1;
  }

  .userBar.shrink {
    min-width: 350px;
    flex-basis: 350px;
    width: 350px;
  }
}

body .listGroupTable .ui-dropdown {
  width: auto;
  min-width: 5em;
}

body .listGroupTable input {
  width: auto;
}

tr.export-log td:first-child {
  padding-left: 30px;
}

tr.export-log td {
  font-size: 0.85em;
  padding-top: 5px;
  padding-bottom: 5px;
}

tr.export-error-message td:first-child {
  padding-left: 30px;
}

tr.export-error-message td div{
  display: contents;
}

tr.export-error-message > td {
  font-size: 0.85em;
  padding-top: 5px;
  padding-bottom: 5px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

#applicableMeasuresModal h4 {
  margin-bottom: 0;
}

#applicableMeasuresModal h5 {
  margin-top: 0;
}

.bigger-and-bolder-text li a {
  font-size: 18px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.uk-navbar.uk-toolbar {
  padding: 0px 30px 0px 30px;
  margin: 0px -30px 30px;
  font-size: 0.9em;
  position: sticky;
  top: 0;
  z-index: 100;
  min-height: 40px;
}

.uk-modal-header + .uk-modal-body > .uk-navbar.uk-toolbar {
  margin-top: -31px;
}

.uk-modal-body .uk-grid .uk-navbar.uk-toolbar {
  margin-top: 2px;
  margin-left: -40px;
}

.uk-navbar.uk-toolbar .uk-navbar-nav > li > a,
.uk-navbar.uk-toolbar .uk-navbar-nav > li .uk-inline > a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  /* color: #999; */
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  min-height: 40px;
}

.uk-navbar.uk-toolbar .ui-selectbutton.icon-buttons .ui-button,
.icon-buttons .ui-button {
  padding: 3px 6px;
}

.ecom-icon-only .ui-calendar-button,
.p-button.p-button-icon-only {
  color: #0a3748;
}

.ecom-icon-only .ui-calendar-button,
.ecom-icon-only .ui-calendar-button:hover,
.p-button.p-button-icon-only,
.p-button.p-button-icon-only:hover {
  background: none !important;
  border-color: transparent !important;
}

.ecom-icon-only .ui-calendar-button:hover,
.p-button.p-button-icon-only:hover {
  color: #2b99d4 !important;
  text-decoration: underline !important;
}

/* p-calendar {
  max-width: 120px;
  display: inline-block;
} */

span.ui-calendar {
  width: 100%;
}

span.ui-calendar.ecom-icon-only {
  width: auto;
}

.non-active-info {
  color: #aaa;
}

.uk-form p-fieldset legend {
  width: auto;
  border: 0;
  padding: 0;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 30px;
}

.uk-form p-fieldset legend:after {
  border: 0;
}

.uk-form p-fieldset fieldset {
  padding: 0 1em 1em;
  margin: 0 0 15px 0;
}

body .ui-inputgroup .ui-inputgroup-addon.field-label {
  flex: 0 0 30px;
  background-color: #f9f9f9;
  border-right: 0;
  border-radius: 0;
}

.btnControls {
  margin: 20px 0 50px;
}

input.uk-input[type='radio'] {
  border-radius: 50%;
}
.p-inputtext {
  padding: 5px 5px 5px 8px;
}
.pi {
  font-size: 0.6rem;
}
.p-dropdown .p-dropdown-trigger {
  width: 1.3rem;
}
.p-dropdown-filter-icon {
  margin-top: -0.2rem !important;
}
.uk-navbar {
  background: #eee;
  color: #999;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #eee;
}

.uk-navbar-brand:before,
.uk-navbar-content:before,
.uk-navbar-toggle:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/* new fixes for uikit 3 */
.toggleUserMenu.uk-hidden,
.toggleUserMenu[hidden] {
  display: block !important;
}

.uk-search-default .uk-search-input {
  height: 30px;
}

.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 30px;
}

.filterHolderSmall.tullagerTop select {
  margin-right: 5px;
}

.small-input-margin {
  margin-bottom: 3px;
}

.uk-form.input-height-small input,
input.uk-input.input-height-small {
  height: 20px;
}

.uk-navbar-content {
  height: 40px;
  padding: 0 15px;
}

.uk-navbar-nav > li > a.small-li-btn {
  min-height: unset;
  height: 40px;
  text-transform: none;
}

.uk-navbar-nav > li:hover a.small-li-btn {
  background-color: #ddd;
}

.uk-navbar-transparent {
  background: none !important;
}

img.footer-logo {
  width: 150px;
}

.padding-small-nav {
  padding: 5px;
}

.uk-form .uk-form-thin-input input[type='text'] {
  padding: 0;
}

.case-status-firstline {
  color: #63b4e4;
}

.case-status-case-manager {
  color: #ffdc3d;
}

.case-status-customs-waiting {
  color: #c3e2c1;
}

.case-status-fail {
  color: #ff3864;
}

.case-status-customs-cleared {
  color: #6c9656;
}

body .flag-icon {
  background-size: cover;
}

.mt-20 {
  margin-top: 20px;
}

.uk-button-danger {
  background-color: #d85030;
  color: #fff;
}

.absolute-overflow {
  position: relative;
  width: 100%;
}

.absolute-overflow > * {
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: auto;
}
.small-padding-top > uikit-form-element > div {
  padding-top: 3px !important;
}

.declaration-container .ui-dropdown,
.declaration-container .p-dropdown {
  background: #0a3748;
  border: none;
}

.declaration-container .ui-dropdown .ui-dropdown-trigger,
.declaration-container .p-dropdown {
  background: #0a3748;
}

.declaration-container .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label,
.declaration-container .p-dropdown .p-inputtext,
.declaration-container .p-dropdown .p-dropdown-trigger,
.declaration-container .p-dropdown .p-dropdown-clear-icon {
  color: #fff;
}

.declaration-container > label {
  padding-top: 5px;
}

.custom-button-color {
  background: #0a3748;
}

.move-to-left {
  margin-left: -30px;
}
.cases-overview-table .p-datatable .p-datatable-tbody > tr > td:last-of-type,
.cases-overview-table
  .p-datatable.p-datatable-sm
  .p-datatable-thead
  > tr
  > th:last-of-type {
  text-align: right;
  width: 120px;
  position: sticky;
  right: 0;
  background-color: #fff;
}

.cases-overview-table
  .finance-overview
  .p-datatable
  .p-datatable-tbody
  > tr
  > td:last-of-type,
.cases-overview-table
  .finance-overview
  .p-datatable.p-datatable-sm
  .p-datatable-thead
  > tr
  > th:last-of-type {
  width: 50px;
}

.p-datatable .p-datatable-tbody > tr {
  color: #0a3748;
}

.cases-overview-table
  .p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover
  > td:last-of-type {
  background-color: #e9ecef;
  color: #495057;
}

a.increased-a-area {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0em 1em;
}

.uk-badge {
  font-size: 12px;
  padding: 2.5px 5px;
  border-radius: 0;
}

.uk-badge:hover{
  color: #fff !important;
  text-decoration: none;
  outline: 0;
}

.uk-badge i {
  margin-right: 2.5px;
}

.badge-green {
  background-color: #b6efc3;
  color: #27793a !important;
}
.badge-red {
  background-color: #f09595;
  color: #7b0e0e !important;
}
.badge-grey {
  background-color: #cdd4d8;
  color: #0d2e40 !important;
}
.badge-yellow {
  background-color: #fcdea8;
  color: #6e501a !important;
}
.badge-blue {
  background-color: #bfe4f7;
  color: #236080 !important;
}
.badge-black {
  background-color: #000000;
  color: #888888 !important;
}

.tieredmenu-right.p-tieredmenu .p-menuitem-active > p-tieredmenusub > .p-submenu-list {
  right: 100%;
  left: unset !important;
}

ul.p-listbox-list, ol.p-listbox-list {
  padding: 0.5rem 0;
}

.uk-grid-divider > :first-child::before {
  content: none;
}
